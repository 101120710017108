import React from 'react'
import './Product.css'

const Portions = (props) => {
  return (
    <div className='box-container-two'>
      <div className="product-title">
        <h1 style={{ color: props.product.color }}>{props.product.name}</h1>
        <hr />
      </div>
      <div className="titulo-tabla">
        <p style={{ color: props.product.color }}> Tabla de raciones</p>
      </div>
      <div className="portions-table">
        <img src={props.product.ration_table_image_url} alt='table' />
      </div>
      <div className="portions-table-footer">
        <p><span>*</span>1 taza = 100gr</p>
        <p>Esta tabla es referencial, adapte las cantidades de acuerdo a la condición física de su perro.</p>
        <br />
      </div>
      <div className="portions-recomendation ">
        <h3 style={{ color: props.product.color }}>Recomendación</h3>
        <p>{props.product.recomendation}</p>
      </div>
      <div className="blue-balls">
        <img src={props.product.recomendation_table_image_url} alt='recomendation' />
        <p>Según recomendación Médico Veterinario</p>
      </div>

    </div>
  )
}

export default Portions