import React from 'react'
import PropTypes from 'prop-types'
import '../BackDrop/BackDrop.css'

const backdrop = props => (
  <div className="backdrop" onClick={() => props.handleClick(false)} />
)

backdrop.propTypes = {
  handleClick: PropTypes.func
}

export default backdrop
