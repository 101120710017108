import React from 'react'
import PropTypes from 'prop-types'
import styles from './DrawerToggleButton.module.css'

const DrawerToggleButton = props => (
  <button className={styles.toggle_button} onClick={() => props.handleClick(!props.showDrawer)}>
    <div className={styles.toggle_button_line} />
    <div className={styles.toggle_button_line} />
    <div className={styles.toggle_button_line} />
  </button>
)

DrawerToggleButton.propTypes = {
  handleClick: PropTypes.func
}

export default DrawerToggleButton
