import React, { useState } from 'react'
import BackDrop from '../BackDrop/BackDrop'
import SideDrawer from '../SideDrawer/SideDrawer'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import './Layout.css'

const Layout = (props) => {
  const [showSideDrawer, setShowSideDrawer] = useState(false)

  let layoutClassName
  if (props.active === 'home' || props.active === 'contact-us' || props.active === 'about-us') {
    layoutClassName = 'content-home'
  } else {
    layoutClassName = 'content'
  }

  let backdrop = <div></div>
  if (showSideDrawer) {
    backdrop = <BackDrop handleClick={setShowSideDrawer} />
  }

  return (
    <div className='layout-container'>
      {backdrop}
      <Navbar active={props.active} onMenuClick={setShowSideDrawer} showDrawer={showSideDrawer} />
      <SideDrawer show={showSideDrawer} setShow={setShowSideDrawer} />
      <div className={layoutClassName}>
        {props.children}
      </div>
      <Footer />
    </div>
  )
}

export default Layout