import React from 'react'
import Slider from 'react-slick'
import { Link } from 'react-router-dom'
import './Carousel.css'

const Carousel = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    autoplaySpeed: 5000
  }
  return (
    <div className="carousel">
      <div className="responsive-buttons">
        <div className="responsive-btn">
          <a href="tel://+56225651386">
            <img src="img/iconsk2_phone.png" alt="Telefono"></img>
          </a>
        </div>
        <div className="responsive-btn">
          <a href="mailto:info@comercialk2.cl">
            <img src="img/Iconsk2_Mail.png" alt="Mail"></img>
          </a>
        </div>
        <div className="responsive-btn">
          <a href="https://www.instagram.com/comercial_k2" target="_blank" rel="noopener noreferrer">
            <img src="img/Iconsk2_Instagram.png" alt="Instagram"></img>
          </a>
        </div>
        <div className="responsive-btn">
          <a href="whatsapp://send?phone=+56225651386">
            <img src="img/Iconsk2_Whatsapp.png" alt="Telefono"></img>
          </a>
        </div>
      </div>
      <Slider {...settings}>
        <div className="slide-1">
          <h1>alimento para<br />mascotas</h1>
          <h4>Con óptimos nutrientes para todas las etapas<br />del crecimiento y bienestar de tu mascota.</h4>
        </div>
        <div className="slide-2">
          <div className="slide-2-superrico-img">
            <img src="/img/superrico.png" alt="Super Rico" />
          </div>
          <div className="slide-2-dogs-img">
            <img src="/img/banner_delipet.png" alt="Banner Delipet" />
          </div>
          <div className="slide-2-delipet-img">
            <img src="/img/delipet.png" alt="Delipet" />
          </div>
          <div className="slide-2-delipet-txt">
            <p>Alimento completo balanceado<br />para <strong>cachorros, adultos y gatos</strong></p>
          </div>
          <div className="slide-2-ver-mas">
            <Link to={{ pathname: '/nuestras-marcas', query: { brand: 'delipet' }}}>
              <div>
                ver más
              </div>
            </Link>
          </div>
          <div className="slide-2-delipet-food-img">
            <img src="/img/delipet_food.png" alt="Delipet Food" />
          </div>
        </div>
        <div className="slide-3">
          <div className="slide-3-can-buffet-dogs-img">
            <img src="/img/canbuffet_dogs.png" alt="Can Buffet Dogs" />
          </div>
          <div className="slide-3-can-buffet-img">
            <img src="/img/canbuffett.png" alt="Can Buffet" />
          </div>
          <div className="slide-3-can-buffet-btn">
            <Link to={{ pathname: '/nuestras-marcas', query: { brand: 'Can Buffet' }}}>
              <div>
                ver más
              </div>
            </Link>
          </div>
          <div className="slide-3-can-buffet-text">
            <p>Alimento completo balanceado<br />para <strong>perros adultos</strong></p>
          </div>
          <div className="slide-3-can-buffet-food-img">
            <img src="/img/canbuffet.png" alt="Can Buffet Food" />
          </div>
        </div>
        <div className="slide-4">
          <div className="slide-4-yum-dog-img">
            <img src="/img/yum_dog.png" alt="Yum Dog" />
          </div>
          <div className="slide-4-yum-img">
            <img src="/img/yum_logo.png" alt="Yum" />
          </div>
          <div className="slide-4-yum-text">
            <p>Alimento completo para <strong>perros adultos</strong><br />fortificado con vitaminas y minerales</p>
          </div>
          <div className="slide-4-yum-btn">
            <Link to={{ pathname: '/nuestras-marcas', query: { brand: 'Yum' }}}>
              <div>
                ver más
              </div>
            </Link>
          </div>
          <div className="slide-4-yum-food-img">
            <img src="/img/yum.png" alt="Yum Food" />
          </div>
        </div>
      </Slider>
    </div>
  )
}

export default Carousel