import React from 'react'

const Instructions = (props) => {
  const showInstructions = (product) => {
    return product.instructions.map((i) => {
      return <p><span style={{color: product.color}}>{'> '}</span>{i}</p>
    })
  }

  return (
    <div className='box-container-two'>
      <div className="product-title">
        <h1 style={{color: props.product.color}}>{props.product.name}</h1>
        <hr />
      </div>
      <div className='instructions-list-product'>
        <h3 style={{color: props.product.color}}>Intrucciones de uso:</h3>
        {showInstructions(props.product)}
      </div>
    </div>
  )
}

export default Instructions