import React, { useState } from 'react'
import './Product.css'
import Layout from '../Layout/Layout'
import { Link, Redirect } from 'react-router-dom'
import ProductInfo from './ProductInfo'
import Portions from './Portions'
import Instructions from './Instructions'
import ProductInfoMobile from './ProductInfoMobile'

const Product = (props) => {
  const [view, setView] = useState('product')

  const p = props.location.query ? props.location.query.product : null
  if (!p) {
    return (<Redirect to="/nuestras-marcas" />)
  }

  const viewChange = (view) => {
    setView(view)
  }

  return (
    <Layout>
      <div className="container-product-detail">
        <div className="box-container-one">
          <div className="images-delipet">
            <div className="delipet-logo">
              <img src="img/delipet.png" alt='delipet' />
            </div>
            <div className="delipet-product">
              <img src={p.img_url} alt='delipet' />
            </div>
          </div>
        </div>

        <div className="box-container-two-wrapper">
          <div className="headings">
            <div className={view === 'product' ? 'active product-heading' : 'product-heading'} onClick={() => viewChange('product')}>
              <h3>Producto</h3>
            </div>
            <div className={view === 'portions' ? 'active portions-heading' : 'portions-heading'} onClick={() => viewChange('portions')}>
              <h3>Raciones</h3>
            </div>
            <div className={view === 'instructions' ? 'active instructions-heading' : 'instructions-heading'} onClick={() => viewChange('instructions')}>
              <h3>Instrucciones</h3>
            </div>
          </div>
          {view === 'product' ? (window.innerWidth >= 1500 ? <ProductInfo product={p} /> : <ProductInfoMobile product={p} />) : null}
          {view === 'portions' ? <Portions product={p} /> : null}
          {view === 'instructions' ? <Instructions product={p} /> : null}
        </div>
      </div>

      <Link to="/nuestras-marcas">
        <div className="back-brands">
          <button>VOLVER A MARCAS</button>
        </div>
      </Link>
    </Layout >
  );
}

export default Product