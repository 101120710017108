import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import styles from './SideDrawer.module.css'

const SideDrawer = props => {
  let className = styles.side_drawer
  if (props.show) {
    className = `${styles.side_drawer} ${styles.open}`
  }
  return (
    <nav className={className}>
      <ul>
        <div onClick={() => props.setShow(false)} className={styles.close}>X</div>
        <li>
          <Link to="/">Home</Link>
        </li>
        <hr />
        <li>
          <Link to="/nuestras-marcas">Nuestras Marcas</Link>
        </li>
        <hr />
        <li>
          <Link to="/quienes-somos">Quiénes Somos</Link>
        </li>
        <hr />
        <li>
          <Link to="/contactanos">Contáctanos</Link>
        </li>
      </ul>
    </nav>
  )
}

SideDrawer.propTypes = {
  show: PropTypes.any
}

export default SideDrawer
