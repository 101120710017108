import React from 'react'
import {
  Link
} from 'react-router-dom'

import DrawerToggleButton from '../SideDrawer/DrawerToggleButton'
import './Navbar.css'

const Navbar = (props) => {
  const active = { color: "#fcba03" }
  return (
    <div>
      <nav className="navbar">
        <div className="burger">
          <DrawerToggleButton handleClick={props.onMenuClick} showDrawer={props.showDrawer} />
        </div>
        <div className="logo">
          <Link to="/">
            <img src="img/logo.png" alt="Home"></img>
          </Link>
        </div>
        <div className="search"></div>
        <ul>
          <li>
            <Link style={props.active === "home" ? active : {}} to="/">Home</Link>
          </li>
          <li>
            <Link style={props.active === "brands" ? active : {}} to="/nuestras-marcas">Nuestras Marcas</Link>
          </li>
          <li>
            <Link style={props.active === "about-us" ? active : {}} to="/quienes-somos">Quiénes Somos</Link>
          </li>
          <li>
            <Link style={props.active === "contact-us" ? active : {}} to="/contactanos">Contáctanos</Link>
          </li>
        </ul>
        <div className="icons">
          <a href="tel://+56225651386">
            <img src="img/telefono.png" alt="Telefono"></img>
          </a>
          <a href="mailto:info@comercialk2.cl">
            <img src="img/mail.png" alt="Mail"></img>
          </a>
          <a href="https://www.instagram.com/comercial_k2" target="_blank" rel="noopener noreferrer">
            <img src="img/instagram.png" alt="Instagram"></img>
          </a>
        </div>
      </nav>
    </div>
  )
}

export default Navbar