import React from 'react'

const ProductInfoMobile = (props) => {
  return (
    <div className='box-container-two'>
      <div className="product-top-mobile">
        <div className="images-delipet">
          <div className="product-detail-image">
            <img src={props.product.img_url} alt='delipet' />
          </div>
        </div>
        <div>
          <h1 style={{ color: props.product.color }}>{props.product.name}</h1>
          <h2>{props.product.amount}</h2>
          <div className="delipet-logo-mobile">
              <img src="img/delipet.png" alt='delipet' />
          </div>
          <div className="product-top-right-mobile">
            <div className="titulo-tabla">
              <p style={{ color: props.product.color }}>Analisis garantizado (BMS<span>*</span>):</p>
            </div>

            <table className="ingredients-table">
              <thead></thead>
              <tbody>
                <tr>
                  <td>Proteina</td>
                  <td>{props.product.nutritional.protein}</td>
                </tr>
                <tr>
                  <td>Fibra</td>
                  <td>{props.product.nutritional.fibre}</td>
                </tr>
                <tr>
                  <td>Materia Grasa</td>
                  <td>{props.product.nutritional.fat}</td>
                </tr>
                <tr>
                  <td>Humedad</td>
                  <td>{props.product.nutritional.humidity}</td>
                </tr>
              </tbody>
            </table>
            <div className="asterisc">
              <p>Base materia seca<span>*</span></p>
            </div>
          </div>
        </div>
      </div>
      <div className="product-bottom-mobile">
        <div className="three-icons">
          <div className="icon1">
            <img src={props.product.icon1} alt="" />
            <p className="p1">{props.product.benefits1}</p>
          </div>
          <div className="icon2">
            <img src={props.product.icon2} alt="" />
            <p className="p2">{props.product.benefits2}</p>
          </div>
          <div className="icon3">
            <img src={props.product.icon3} alt="" />
            <p className="p3">{props.product.benefits3}</p>
          </div>
          {props.product.icon4 ? <div className="icon4">
            <img src={props.product.icon4} alt="" />
            <p className="p3">{props.product.benefits4}</p>
          </div> : null}
          {props.product.icon5 ? <div className="icon5">
            <img src={props.product.icon5} alt="" />
            <p className="p3">{props.product.benefits5}</p>
          </div> : null}
          {props.product.icon6 ? <div className="icon6">
            <img src={props.product.icon6} alt="" />
            <p className="p3">{props.product.benefits6}</p>
          </div> : null}
        </div>
        <div className="product-description">
          <p>
            {props.product.description}
          </p>
        </div>
        <div className="ingredients">
          <h1 style={{ color: props.product.color }}> Ingredientes:</h1>
          <p>
            {props.product.ingredients}
          </p>
        </div>
      </div>
    </div>
  )
}

export default ProductInfoMobile