import React from 'react'
import './Finder.css'
import { Link } from 'react-router-dom'

const Finder = () => {
  return (
    <div className="finder">
      <div className="finder-container">
        <div className="title">
          <h1>¿Para quién<br />estás buscando alimento?</h1>
          <div className="title-separator">
            <hr />
            <img src='img/paw-icon.png' alt='paw-icon' />
            <hr />
          </div>
        </div>
        <div className="search-options">
          <div>
            <img src='img/cachorro.png' alt='cachorros' />
            <Link to={{ pathname: '/nuestras-marcas', query: { category: 'cachorro' }}}>
              <div>
                Cachorros
              </div>
            </Link>
          </div>
          <div className="perro-adulto">
            <img src='img/perroadulto.png' alt='adultos' />
            <Link to={{ pathname: '/nuestras-marcas', query: { category: 'adulto' }}}>
              <div>
                Adultos
              </div>
            </Link>
          </div>
          <div>
            <img src='img/gato.png' alt='gatos' />
            <Link to={{ pathname: '/nuestras-marcas', query: { category: 'gato' }}}>
              <div>
                Gatos
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Finder