import React from 'react'

import Carousel from './components/Carousel/Carousel'
import Finder from './components/Finder/Finder'
import Layout from '../Layout/Layout'

const Home = () => {
  return (
    <div>
      <Layout active="home">
        <Carousel />
        <Finder />
      </Layout>
    </div>
  )
}

export default Home