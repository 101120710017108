import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../Layout/Layout'
import products from '../products'
import './OurBrands.css'

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

class OurBrands extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showProducts: [...products],
      brands: [],
      categories: [],
      amounts: [],
      selectedBrand: null,
      selectedCategory: null,
      selectedAmount: null,
      openFilter: false
    }
    this.deleteFilters = this.deleteFilters.bind(this)
  }

  componentDidMount() {
    let brands = []
    let categories = []
    let amounts = []

    products.forEach((p) => {
      if (!brands.includes(p.brand.toLowerCase())) {
        brands.push(p.brand.toLowerCase())
      }

      if (!categories.includes(p.category.toLowerCase())) {
        categories.push(p.category.toLowerCase())
      }

      if (!amounts.includes(p.amount.toLowerCase())) {
        amounts.push(p.amount.toLowerCase())
      }
    })

    this.setState({ categories })
    this.setState({ brands })
    this.setState({ amounts })

    if (this.props.location.query) {
      if (this.props.location.query.category) {
        this.handleFilter(this.props.location.query.category, 'category')
      }

      if (this.props.location.query.brand) {
        this.handleFilter(this.props.location.query.brand, 'brand')
      }
    }
  }

  showProducts() {
    return this.state.showProducts.map((p, index) => {
      return (
        <Link key={index} to={{ pathname: '/producto', query: { product: p } }} >
          <div className="product-box">
            <img src={p.img_url} alt={p.name} />
            <p>{p.name}<br /><span>- {p.amount} -</span></p>
          </div>
        </Link>
      )
    })
  }

  handleFilter(name, type) {
    let showProducts = products
    if (type === 'brand') {
      showProducts = products.filter((p) => {
        return p.brand.toLowerCase() === name.toLowerCase()
      })
      this.setState({ selectedBrand: name.toLowerCase(), selectedCategory: null, selectedAmount: null })
    }
    if (type === 'category') {
      showProducts = products.filter((p) => {
        return p.category.toLowerCase() === name.toLowerCase()
      })
      this.setState({ selectedCategory: name.toLowerCase(), selectedBrand: null, selectedAmount: null })
    }
    if (type === 'amount') {
      showProducts = products.filter((p) => {
        return p.amount.toLowerCase() === name.toLowerCase()
      })
      this.setState({ selectedCategory: null, selectedBrand: null, selectedAmount: name.toLowerCase() })
    }
    this.setState({ showProducts })
  }

  deleteFilters() {
    this.setState({
      showProducts: [...products],
      selectedAmount: null,
      selectedBrand: null,
      selectedCategory: null
    })
  }

  render() {
    const deleteFilters = this.state.selectedAmount != null ||
      this.state.selectedBrand != null ||
      this.state.selectedCategory != null ?
      <div onClick={this.deleteFilters} className="delete-filters-brands">Eliminar Filtros</div> : null
    
    console.log(window.innerWidth)
    return (
      <Layout active="brands">
        <div className="brands-container">
          <div className="brands-title">
            <h1>Nuestras Marcas</h1>
            <div className="brands-title-separator">
              <hr />
              <img src='img/paw-icon.png' alt='paw-icon' />
              <hr />
            </div>
          </div>
          <div className="brands-content">
            <div className="brands-filter">
              {this.state.openFilter || window.innerWidth >= 768 ? <div className="brands-filter-container">
                {window.innerWidth < 768 ? <div className="close-filter-options" onClick={() => { this.setState({ openFilter: false }) }}>
                  X
                </div> : null}
                <h1>FILTRAR:</h1>
                {deleteFilters}
                <div className="filter-by-brand">
                  <h1>MARCA</h1>
                  {this.state.brands.map((b, index) =>
                    <div
                      className={this.state.selectedBrand === b ? 'clicked' : ''}
                      onClick={() => this.handleFilter(b, 'brand')}
                      key={index}>
                      {capitalize(b)}
                    </div>)}
                </div>
                <div className="filter-by-category">
                  <h1>CATEGORÍA</h1>
                  {this.state.categories.map((b, index) =>
                    <div
                      className={this.state.selectedCategory === b ? 'clicked' : ''}
                      onClick={() => this.handleFilter(b, 'category')}
                      key={index}>
                      {capitalize(b)}
                    </div>)}
                </div>
                <div className="filter-by-amount">
                  <h1>PESO</h1>
                  {this.state.amounts.map((b, index) =>
                    <div
                      className={this.state.selectedAmount === b ? 'clicked' : ''}
                      onClick={() => this.handleFilter(b, 'amount')}
                      key={index}>
                      {capitalize(b)}
                    </div>)}
                </div>
              </div>
                : <div className="open-filters-button" onClick={() => { this.setState({ openFilter: true }) }}>
                  <div>
                    Filtros
                  </div>
                </div>}
              </div>
            <div className="brands-product-display">
              {this.showProducts()}
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default OurBrands