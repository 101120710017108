import React from 'react'
import Layout from '../Layout/Layout'
import './AboutUs.css'

const AboutUs = () => {
  return (
    <Layout active="about-us">
      <div className="container-about">
        <div className="title-about">
          <h1>Quiénes Somos</h1>
          <div className="title-separator-about">
            <hr />
            <img src='img/paw-icon.png' alt='paw-icon' />
            <hr />
          </div>
        </div>
        <div className='fabric-about'>
          <h6>
            Somos una empresa dedicada a la producción, fabricación y comercialización de alimentos para mascotas.
            Aseguramos el proceso con <span>materias primas de primera calidad</span>, lo que garantiza un producto con los <span>óptimos
            nutrientes para todas las etapas del crecimiento y bienestar de tu mascota</span>. Nuestra planta está ubicada en
            la ciudad de Santiago, comuna de Lampa, con tecnología de alto nivel utilizando los mejores estándares de producción.
            <br />
            <br />
            Tenemos un <span>excelente equipo humano comprometido</span> con la calidad y la oportuna entrega de sus productos
            a proveedores y consumidores a nivel nacional y estamos en óptimas condiciones para <span>fabricar productos en su
            forma y formulación</span> como son nuestras marcas Delipet, Yum y Can Buffet.
        </h6>
        </div>
        <div className="mission-about">
          <div className="cards">
            <div className="card-mission">
              <img src='img/MISION.png' alt='mision' />
              <h1>MISIÓN</h1>
              <p>
                Fabricamos y comercializamos comida de mascota de calidad a nuestros consumidores con un equipo altamente
                profesional y capacitado para garantizar los estándares que hoy la sociedad nos exige.
              </p>
            </div>
            <div className="card-vission">
              <img src='img/VISION.png' alt='mision' />
              <h1>VISIÓN</h1>
              <p>
                Ser empresa líder nacional a nivel de fabricación y comercialización de alimentos para mascotas y
                reconocidos por nuestros clientes.
              </p>
            </div>
          </div>
          <div className="down-image-container">
            <div className="about-logos">
              <img src="img/logocollection.png" alt='delipet' />
            </div>
            <div className="about-cat">
              <img src="img/gatoa.png" alt="gato" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AboutUs