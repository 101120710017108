import React from 'react'
import Layout from '../Layout/Layout'
import './ContactUs.css'

const ContactUs = () => {
  return (
    <Layout active="contact-us">
      <div className='contact-container-wrapper'>
        <div className="contact-us-title">
            <h1>¡Contáctanos!</h1>
            <div className="contact-us-title-separator">
              <hr />
              <img src='img/paw-icon-white.png' alt='paw-icon' />
              <hr />
            </div>
        </div>
        <form method="POST" action="https://formspree.io/mwkranod" className='contact-form-container'>
          <div className='contact-form-inputs'>
            <div className='first-input-row-contact'>
              <input type='text' placeholder='Nombre *' name='name' required />
              <input type='text' placeholder='Apellido *' name='apellido' required />
            </div>
            <div className='second-input-row-contact'>
              <input type='text' placeholder='Teléfono *' name='telefono' />
            </div>
            <div className='third-input-row-contact'>
              <input type='email' placeholder='E-mail *' name='_replyto' required />
            </div>
            <div className='fourth-input-row-contact'>
              <textarea placeholder='Mensaje *' name='mensaje' required />
            </div>
          </div>
          <div className='contact-form-footer'>
            <div className='contact-form-footer-info'>
              <div>
                <img src='img/mail.png' alt='email' />
                <p>info@comercialk2.cl</p>
              </div>
              <div>
                <img src='img/telefono.png' alt='phone' />
                <p>+56 22 565 1386</p>
              </div>
              <div>
                <img src='img/ubicacion.png' alt='location' />
                <p>La Cordillera 1111, módulo B y D, Lampa, Santiago</p>
              </div>
              <div>
                <img src='img/reloj.png' alt='horario' />
                <p>Lunes a viernes de 07:00 a 17:00</p>
              </div>
            </div>
            <div className='contact-form-submit'>
              <input type="submit" value="Enviar" />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export default ContactUs