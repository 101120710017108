import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="info">
          <p><span>info@comercialk2.cl</span></p>
          <p>instagram: <span>@comercial_k2</span></p>
          <p>Teléfono: <span>+56 22 565 1386</span></p>
          <p>Dirección: <span>La Cordillera 1111, módulo B, Lampa</span></p>
          <p>Horario: <span>Lunes a viernes de 07:00 a 17:00</span></p>
        </div>
        <div className="logo-footer">
          <img src='img/logofooter.png' alt='logo' />
        </div>
        <div className="extras">
          <p>&copy; 2022 Comercial K2 - Todos los derechos reservados.</p>
          <p>Todas las marcas registradas, nombres comerciales,</p>
          <p>logos e imágenes pertenecen a sus respectivos dueños.</p>
        </div>
      </div>
    </div>
    
  )
}

export default Footer
