import React from 'react'
import './App.css'
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import OurBrands from "./components/OurBrands/OurBrands"
import AboutUs from "./components/AboutUs/AboutUs"
import ContactUs from "./components/ContactUs/ContactUs"
import Home from "./components/Home/Home"
import Product from './components/Product/Product'
import Analytics from 'react-router-ga'

function App() {
  return (
    <div className="App">
      <Router>
        <Analytics id="UA-170035190-1">
          <Switch>
            <Route exact path="/nuestras-marcas" component={OurBrands} />
            <Route exact path="/producto" component={Product} />
            <Route exact path="/quienes-somos" component={AboutUs} />
            <Route exact path="/contactanos" component={ContactUs} />
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </Analytics>
      </Router>
    </div>
  );
}

export default App;
