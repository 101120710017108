const products = [
  {
    id: 0,
    name: "Delipet Cachorro",
    amount: "10 kg",
    img_url: 'img/delipetcachorro.png',
    color: '#33A7C6',
    description: `
      Delipet Cachorro tiene una excelente combinación de ingredientes, con
      el cual aseguramos un mayor aporte de proteínas y grasas de excelente
      calidad para así fortalecer el crecimiento de tu mascota. Está formulado
      con prebióticos que contribuyen a tener un sistema digestivo más sano y
      de mejor absorción y un correcto desarrollo de la flora intestinal. Contiene
      Omega 6, antioxidante natural que contribuye a tener un pelaje más sano
      y brillante y a fortalecer sus huesos.
    `,
    nutritional: {
      protein: "28% mínimo",
      fibre: "3,5% máximo",
      fat: "10% mínimo",
      humidity: "12% máximo"
    },
    ingredients: `
      Maíz, harinilla de cereal (trigo, arroz), harina de subproductos de ave, harina de carne y hueso
      (vacuno, cerdo) , gluten de maíz, aceite de ave y cerdo (estabilizado con antioxidantes BHT/
      BHA), hidrolizado de hígado (ave, bovino), premix vitamínico mineral ( potasio, magnesio,
      vitamina A, colina, vitamina S3, zinc, vitamina E, hierro, niacina, vitamina B5, cobre, vitamina B2,
      manganeso, vitamina B1, vitamina B6, vitamina K3, ácido fólico, yodo, vitamina H, selenio,
      vitamina B12), cloruro de sodio, DHA, atenuante de olor (extracto de yucca), prebióticos,
      carbonato de calcio, colorantes autorizados, anti fúngicos autorizados, leche en polvo. 
    `,
    benefits1: `Enriquecido con vitaminas y minerales `,
    benefits2: `Buena Digestión `,
    benefits3: `Huesos Sanos`,
    icon1: `img/vitaminasazules.png`,
    icon2: `img/rinonazul.png`,
    icon3: `img/bluebone.png`,
    instructions: [
      "Limpie el plato de su perro antes de darle el alimento",
      "Distribuya la ración diaria de Delipet en al menos 2 veces al día",
      "Mantenga siempre agua fresca a disposición de su perro",
      "Evite exponer el alimento al sol, roedores, pájaros y sustancias tóxicas"
    ],
    ration_table_image_url: 'img/racionesCachorro.png',
    recomendation: `
      Al comenzar la alimentación de su mascota con Delipet tenga la
      precaución de incorporarlo de forma gradual, mezclándolo con el alimento
      anterior para que su mascota se acostumbre de forma adecuada.
    `,
    recomendation_table_image_url: 'img/pelotasAzules.png',
    category: "cachorro",
    brand: "delipet"
  },
  {
    id: 1,
    name: "Can Buffet Adulto",
    amount: "22 kg",
    img_url: 'img/canbuffet.png',
    color: '#F6B161',
    description: `
    Can Buffet es un alimento 100% balanceado para tu perro
    adulto, con el cual aseguramos la mantenció
    n y cuidado de ella. Su fórmula contiene
    los nutrientes necesarios para mantener en equilibrio su organismo
    `,
    nutritional: {
      protein: "20% mínimo",
      fibre: "6% máximo",
      fat: "4% mínimo",
      humidity: "10% máximo"
    },
    ingredients: `
    Maíz, harinilla de cereal (trigo), harina de carne y hueso (vacuno y cerdo), harina de soya, 
    aceite de ave, (estabilizados con antioxidantes BHT/ BHA ), hidrolizado de hígado(ave / cerdo) 
    ,cloruro de sodio, anti fúngico autorizado, carbonato de calcio, colorantes autorizados, premix 
    Minerales: Potasio, magnesio, Selenio; Cobre, Zinc, Hierro y Yodo
    Vitaminas: A, D3, E, B1, B2, B5, B6, B12, K3, H, ácido fólico, cloruro de colina (B), niacina, (B3) 
    
    `,
    instructions: [
      "Limpie el plato de su perro antes de servir CAN BUFFET",
      "Distribuya la ración diaria en al menos 2 veces al día",
      "Siempre mantenga agua a disposición de su mascota. ",
      "Evite que CAN BUFFET se exponga al sol, roedores, pájaros y sustancias tóxicas"
    ],
    ration_table_image_url: 'img/buffet-table.png',
    recomendation: `
    Al comenzar la alimentación de su mascota con CAN BUFFET tenga la precaución de incorporarlo de forma gradual, 
    mezclándolo con el alimento anterior para que su mascota se acostumbre de forma adecuada
    `,
    benefits2: `Alimento balanceado con vitaminas y minerales para perros sanos y fuertes `,
    icon2: `img/yellowvitamin.png`,
    table_footer: `Esta tabla es referencial, adapte las cantidades de acuerdo a la condición física de su perro.`,
    recomendation_table_image_url: 'img/buffet-rec.png',
    category: "adulto",
    brand: "Can Buffet"
  },
  {
    id: 2,
    name: "Delipet Gato",
    amount: "10 kg",
    img_url: 'img/delipetgato.png',
    color: '#5A398B',
    description: `
    Delipet Gato es un alimento completo y balanceado, especialmente desarrollado para gatos adultos. 
    Está formulado con ingredientes de primera calidad con los cuales aseguramos que sea un alimento que cumpla con 
    todos los requerimientos nutricionales de tu mascota . Es un alimento altamente palatable, rico en proteínas, 
    grasas y enriquecido con Taurina. Contribuye a mantener un estado óptimo de salud, 
    contiene Omega 3 y 6 que ayuda a mantener una piel y pelaje sanos y brillante.  Su bajo contenido de sodio 
    y potasio ayuda a mantener la salud del sistema urinario.

    `,
    nutritional: {
      protein: "30% mínimo",
      fibre: "6% máximo",
      fat: "9% mínimo",
      humidity: "12% máximo",
      calcium: "1%",
      phosphorus: "1%",
    },
    ingredients: `
    Maíz, subproducto de cereal (trigo, arroz), harina de carne de bovino y/o ave y/o cerdo, 
    harina de subproducto de ave,  harina de soya,  grasa animal bovina (estabilizada con 
      antioxidantes BHT /BHA),  realzador de sabor,  cloruro de sodio,  ácido cítrico, DL metionina, 
      cloruro de potasio, cloruro de colina, propionato de calcio y sodio, sulfato ferroso,  DL-Alfa
      tocoferol acetato ( fuente de vitamina E), sulfato de zinc, selenito de sodio, sulfato de manganeso, 
      suplemento de riboflavina ( vitamina B2), sulfato de cobre, niacina, suplemento de vitamina B12,  
      suplemento de vitamina A, pantotenato de calcio, D-Biotina, 
      suplemento de hidrocloruro de piridoxina (vitamina B6), yodato de calcio, mononitrato de tiamina, ácido fólico,  
      suplemento vitamina D3, bisulfito de menadiona, carbonato de calcio, colorante autorizado.
    `,
    instructions: [
      "Limpie el plato de su gato antes de darle el alimento",
      "Distribuya la ración diaria Delipet en al menos 2 veces al día ",
      "Mantenga siempre agua fresca a disposición de su gato  ",
      "Evite exponer el alimento al sol, roedores, pájaros y sustancias tóxicas "
    ],
    ration_table_image_url: 'img/cat-table.png',
    recomendation: `
    Al comenzar la alimentación de su mascota con Delipet tenga la precaución de incorporarlo de forma gradual, 
    mezclándolo con el alimento anterior para que su mascota se acostumbre de forma adecuada
    `,
    benefits1: `Pelaje sano y brillante`,
    benefits2: `Tracto urinario sano`,
    benefits3: `Omega 3 y 6`,
    icon1: `img/delipethair.png`,
    icon2: `img/delipetkidney.png`,
    icon3: `img/delipet36.png`,
    table_footer: `Esta tabla es referencial, adapte las cantidades de acuerdo a la condición física de su perro.`,
    recomendation_table_image_url: 'img/cat-rec.png',
    category: "adulto",
    brand: "Delipet"
  },
  {
    id: 3,
    name: "Delipet Adulto",
    amount: "18 kg",
    img_url: 'img/delipetadulto.png',
    color: '#CB1104',
    description: `
    Delipet Adulto es un alimento premium, especialmente balanceado para satisfacer 
    todos los requerimientos nutricionales de perros adultos.  Al ser un alimento completo, 
    asegura el correcto aporte de vitaminas, minerales, proteínas, grasas y fibra, todos necesarios
     para mantener la salud tu mascota.  Está formulado con ingredientes de excelente calidad lo que 
     asegura el bienestar  de tu perro. En Comercial K2, nos preocupamos de la salud, bienestar y 
     nutrición de las mascotas. Creemos fuertemente en la calidad de nuestros productos y en el valor 
     nutricional de cada uno de ellos.


    `,
    nutritional: {
      protein: "22% mínimo",
      fibre: "4% máximo",
      fat: "8% mínimo",
      humidity: "10% máximo"
    },
    ingredients: `
    Maíz, harinilla de cereal (trigo, arroz), harina de carne y hueso (vacuno, cerdo), 
    harina de soya, grasa de bovino o cerdo (estabilizados con antioxidantes BHT/ BHA), 
    hidrolizado de hígado (ave, bovino) ,cloruro de sodio , premix vitamínico mineral 
    ( potasio, magnesio, vitamina A, colina, vitamina S3, zinc, vitamina E, hierro, niacina,
       vitamina B5, cobre, vitamina B2, manganeso, vitamina B1, vitamina B6, vitamina K3, 
       ácido fólico, yodo, vitamina H, selenio, vitamina B12), carbonato de calcio, 
       anti fúngicos autorizados, colorantes autorizados .
    `,
    instructions: [
      "Limpie el plato de su perro antes de darle el alimento ",
      "Distribuya la ración diaria Delipet en al menos 2 veces al día ",
      "Mantenga siempre agua fresca a disposición de su perro  ",
      "Evite exponer el alimento al sol, roedores, pájaros y sustancias tóxicas "
    ],
    ration_table_image_url: 'img/deli-table.png',
    recomendation: `
    Al comenzar la alimentación de su mascota con Delipet tenga la precaución de incorporarlo de forma gradual, 
    mezclándolo con el alimento anterior para que su mascota se acostumbre de forma adecuada`,
    benefits1: `Enriquecido con vitaminas y minerales `,
    benefits2: `Buena Digestión `,
    benefits3: `Huesos Sanos`,
    icon1: `img/redvitamin.png`,
    icon2: `img/redkidney.png`,
    icon3: `img/redbone.png`,
    table_footer: `Esta tabla es referencial, adapte las cantidades de acuerdo a la condición física de su perro.`,
    recomendation_table_image_url: 'img/deli-rec.png',
    category: "adulto",
    brand: "Delipet"
  },
  {
    id: 4,
    name: "Yum Adulto",
    amount: "20 kg",
    img_url: 'img/yum.png',
    color: '#CB1104',
    description: `
    Yum! es un alimento completo y balanceado de excelente calidad  para perros adultos, 
    con el cual podrás asegurar el equilibrio y mantención en tu mascota. 
    Satisface los niveles requeridos de proteína, grasas, vitaminas y minerales , 
    todos necesarios para asegurar la alimentación balanceada.
    Contiene nutrientes esenciales para mantener un buen sistema inmunitario y un correcto estado de salud.



    `,
    nutritional: {
      protein: "22% mínimo",
      fibre: "4% máximo",
      fat: "8% mínimo",
      humidity: "10% máximo"
    },
    ingredients: `
    Maíz, harinilla de cereal (trigo, arroz), harina de carne y hueso (vacuno, cerdo), 
    harina de soya, grasa de bovino o cerdo (estabilizados con antioxidantes BHT/ BHA), 
    hidrolizado de hígado (ave, bovino) ,cloruro de sodio , premix vitamínico mineral 
    ( potasio, magnesio, vitamina A, colina, vitamina S3, zinc, vitamina E, hierro, niacina,
       vitamina B5, cobre, vitamina B2, manganeso, vitamina B1, vitamina B6, vitamina K3, 
       ácido fólico, yodo, vitamina H, selenio, vitamina B12), carbonato de calcio, 
       anti fúngicos autorizados, colorantes autorizados. .
    `,
    instructions: [
      "Limpie el plato de su perro antes de darle el alimento ",
      "Distribuya la ración diaria Delipet en al menos 2 veces al día ",
      "Mantenga siempre agua fresca a disposición de su perro  ",
      "Evite exponer el alimento al sol, roedores, pájaros y sustancias tóxicas "
    ],
    ration_table_image_url: 'img/yum-table.png',
    recomendation: `
    Al comenzar la alimentación de su mascota con YUM! tenga la precaución de incorporarlo de forma gradual, 
    mezclándolo con el alimento anterior para que su mascota se acostumbre de forma adecuada.`,
    benefits1: `Alimento completo para perros fortficados con vitaminas y minerales`,
    benefits2: `Vigorosidad`,
    benefits3: `Pelaje sano, firme y brillante`,
    benefits4: `Inmunidad  `,
    benefits5: `Carne `,
    benefits6: `Cereales`,
    icon1: `img/yumvitamin.png`,
    icon2: `img/yumclock.png`,
    icon3: `img/yumhair.png`,
    icon4: `img/inmuno.png`,
    icon5: `img/meat.png`,
    icon6: `img/cereal.png`,
    table_footer: `Esta tabla es referencial, adapte las cantidades de acuerdo a la condición física de su perro.`,
    recomendation_table_image_url: "img/yum-rec.png",
    category: "adulto",
    brand: "Yum"
  }
]

export default products